<template>
  <div class="cities">
    <p v-if="citiesProducts" class="pogination__text">
      {{ currentPage }}-{{ Math.ceil(rows / perPage) }} из {{ filter.length }}
    </p>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="citiesProducts"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="filter"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(id)="data">
          <div>{{ data.index + 1 + (currentPage - 1) * perPage }}</div>
        </template>
        <template #cell(auto)="data">
          <button
            class="table-name"
            @click="$router.push(`/database/info-transport/${data.item.id}`)"
          >
            {{ data.item.model ? data.item.model.name : "" }}
          </button>
        </template>
        <template #cell(map)="data">
          <span v-if="data.item.map" class="table-map-indicator is-map-true" />
          <span v-else class="table-map-indicator">-</span>
        </template>
        <template #cell(transports)="data">
          <span v-for="transport in data.item.tranports" :key="transport.id" class="transport"
            >{{ transport.name || transport.transport_id }}
          </span>
        </template>
        <template #cell(zone)="data">
          {{ data.item.region ? data.item.region.title : "" }}
        </template>
        <template #cell(address)="data">
          <span>{{ data.item.cross_dockings ? data.item.cross_dockings.address : "" }}</span>
        </template>
        <template #cell(lat)="data">
          {{ data.item.cross_dockings ? data.item.cross_dockings.lat : "" }} /
          {{ data.item.cross_dockings ? data.item.cross_dockings.long : "" }}
        </template>
        <template #cell(is_cross)="data">
          {{ data.item.is_cross ? "Да" : "Нет" }}
        </template>
        <template #cell(action)="data">
          <div class="flex" style="justify-content: center">
            <a class="table-btn">
              <b-icon
                icon="pencil-fill"
                aria-hidden="true"
                class="mr-2"
                variant="warning"
                @click="$router.push(`/add/new-city?id=${data.item.id}`)"
              />
            </a>
            <b-form-checkbox
              v-model="data.item.is_active"
              @change="changeActive(data.item.id, data.item.is_active)"
            />
          </div>
        </template>
        <template #cell(tranports)="data">
          <p>{{ data.item.tranports }}</p>
        </template>
        <template #cell(cities_cross)="data">
          <p v-for="(item, index) in data.item.cross_cities_id" :key="index">
            {{ listCitiesCross(item) }}
          </p>
        </template>
        <template #cell(type)="data">
          {{ $_cities_translateTypeToRu(data.item.type) }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { cities } from "../../../mixins/cities";
import { mapState } from "vuex";
export default {
  mixins: [cities],
  data() {
    return {
      swowItem: null,
      cityActive: null,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "Номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "name",
          label: "Название",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "map",
          label: "Карта",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
        {
          key: "type",
          label: "Тип",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "tranports",
          label: "Транспорты",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-md",
        },
        {
          key: "zone",
          label: "Направления",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "address",
          label: "Адрес кросс докинга",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        // {
        //   key: 'lat',
        //   label: 'Долгота/Ширина',
        //   sortable: true,
        //   isRowHeader: true,
        //   class: "tb-all",
        // },
        // {
        //   key: 'is_cross',
        //   label: 'Отслеживается',
        //   sortable: true,
        //   isRowHeader: true,
        //   class: "tb-all",
        // },
        {
          key: "cities_cross",
          label: "Города кросс-докинга",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "Действия",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
    };
  },
  computed: {
    rows() {
      if (this.citiesProducts) {
        this.$emit("spinner");
        return this.filter.length;
      }
      return 3;
    },
    ...mapState(["citiesProducts", "textFilter"]),
    filter() {
      return this.citiesProducts.filter((e) => e.name?.indexOf(this.textFilter || "") > -1);
    },
  },
  created() {
    this.$store.dispatch("getCitiesProducts");
  },
  methods: {
    deleteHandler(city_id) {
      let ask = confirm("Are you sure you want to delete?");
      if (ask) {
        this.$api
          .post("/web/delete-city", {
            city_id,
            _method: "DELETE",
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getCitiesProducts");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      }
    },
    changeActive(id, value) {
      this.$api
        .post("/web/city-active", {
          city_id: id,
          is_active: value,
        })
        .then(() => {
          this.$toast.success("Успешно!");
          // this.$store.dispatch('getCitiesProducts')
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
    listCitiesCross(id) {
      let city = "";
      if (this.citiesProducts) {
        this.citiesProducts.forEach((e) => {
          if (e.id === id) {
            city = e.name;
          }
        });
      }
      return city;
    },
  },
  components: {},
};
</script>

<style scoped>
.table-map-indicator {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 1.5rem;
  height: 1rem;
  color: var(--danger);
  font-size: 2rem;
  font-weight: 500;
}
.is-map-true {
  background-color: var(--success);
  width: 0.65rem;
  height: 0.65rem;
}
.table {
  width: 100%;
}
.table-name {
  background: transparent;
}
.pogination,
.pogination__text {
  position: absolute;
  top: -83px;
  right: -3px;
}
.pogination__text {
  right: 128px;
  top: -70px;
}
.page-item:nth-child(2) {
  display: none;
}
.page-item {
  margin-right: 18px;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
}
.sub-header__text {
  margin: 0 20px 0 26px;
  color: #707070;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.cities td.input {
  width: 50px;
}
.transport:not(:last-child):after {
  content: ", ";
}
</style>

export const cities = {
  methods: {
    $_cities_translateTypeToRu(cityType) {
      switch (cityType) {
        case "city":
          return "Город";
        case "village":
          return "Село";
        case "township":
          return "Поселок";
        case "rural district":
          return "Cельский округ";
        default:
          if (typeof cityType === "string" && cityType.length > 0)
            return (cityType[0].toUpperCase() + cityType.slice(1)).replace(/_/g, " ");
          else return cityType;
      }
    },
  },
};
